import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "WHMCS",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/whmcs.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`WHMCS`}</h1>
    <p>{`Easy-to-install Live Chat for WHMCS`}</p>
    <hr></hr>
    <p>{`WHMCS is a billing & web-hosting automation platform, running thousands of web-hosting companies. Using this integration, add the Crisp Live chat in a single click on WHMCS! Combining a Live Chat with your commercial website and your hosting platform on WHMCS is a great way to create conversational experiences with your customers. It gives your company the hedge to get closer to your customer, better understand them and make them feel they are unique. This is a MUST HAVE for every hosting business.`}</p>
    <p>{`Benefits of using Crisp Live Chat and WHMCS:`}</p>
    <ul>
      <li parentName="ul">{`Add the Crisp Live chat in a single click`}</li>
      <li parentName="ul">{`Manage all your conversations from the Crisp Inbox`}</li>
      <li parentName="ul">{`Automatically push user data when someone is already logged-in`}</li>
      <li parentName="ul">{`Automatically adapts to your website language (50+ locales are supported)`}</li>
      <li parentName="ul">{`Engage with website's visitors in a wink`}</li>
      <li parentName="ul">{`Enhance conversion rate by creating more conversations`}</li>
      <li parentName="ul">{`Centralize users information and send the right contextualized message`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      